@use "src/_app/styles/mixins.scss" as *;
.feature__item {
  display: flex;
  flex-direction: column;

  @include respond(tablet) {
    border-bottom: 1px solid #373737;

    &:nth-child(2) {
      order: 3;
    }

    &:nth-child(3) {
      order: 2;
    }

    &:nth-child(4) {
      order: 4;
    }

    &:nth-child(5) {
      order: 5;
      border-bottom: none;
    }
  }

  &__header {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    pointer-events: none;

    @include respond(tablet) {
      pointer-events: auto;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 0;
      padding: 20px 0;
    }

    &__col {
      display: flex;
      align-items: center;
      gap: 12px;

      h3 {
        margin: 0;
        color: var(--white);

        font-weight: 600;

        @include tTitle_1_semibold;

        @include respond(laptop) {
          font-size: 18px;
          line-height: 24px;
        }

        @include respond(tablet) {
          font-size: 16px;
        }

        @include respond(mobileL) {
          max-width: 230px;
        }
      }
    }
  }

  &__icon {
    width: 24px;
    height: 24px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__body {
    color: var(--white);
    margin: 0;
    overflow: hidden;

    @include tSubtitle;

    @include respond(tablet) {
      margin-left: 36px;
    }

    &.feature__item__mobile {
      display: none;

      @include respond(tablet) {
        display: block;
      }
    }

    &.feature__item__desktop {
      @include respond(tablet) {
        display: none;
      }
    }

    span {
      @include respond(tablet) {
        height: 8px;
        display: block;
      }
    }
  }
}

.accordion__btn {
  transition: transform 0.4s;
  display: none;

  @include respond(tablet) {
    display: block;
  }

  path {
    fill: var(--white);
  }

  &_active {
    transform: rotate(180deg);
  }
}
