@use "src/_app/styles/mixins.scss" as *;
.features {
  width: 100%;
  max-width: 1062px;
  margin: auto;
  padding: 44px 48px;
  color: var(--white);
  background: var(--black);
  border-radius: 24px;
  border: 1px solid #141414;

  @include respond(laptop) {
    max-width: 960px;
    padding: 48px 32px;
  }

  @include respond(tabletS) {
    padding: 0;
    background: none;
  }
}

.header {
  margin: 0 0 48px;
  color: var(--white);

  @include tTitle_1_main;

  @include respond(tabletS) {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 24px;
  }
}

.btn {
  margin-top: 60px;
  width: 100%;
  max-width: 134px;

  @include respond(tabletS) {
    max-width: none;
    margin-top: 32px;
  }
}
