@use "src/_app/styles/mixins.scss" as *;
.lang_item {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  min-width: 100%;
  column-gap: 16px;
  padding: 12px 16px;
  background: var(--select_default);
  color: var(--primary);
  cursor: pointer;

  @include transition;

  &:hover,
  &:active {
    background: var(--bgHeaderItemHover);
  }

  img {
    border-radius: 50%;
    overflow: hidden;
  }
}
