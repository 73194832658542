@use "src/_app/styles/mixins.scss" as *;
.btns__wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 62px);
  gap: 16px 18px;
  width: 100%;
  margin-top: 54px;
  margin-bottom: 22px;

  @include respond(tablet) {
    margin-top: 34px;
    margin-bottom: 24px;
  }

  @include respond(mobileL) {
    grid-template-rows: repeat(3, 44px);
    margin-top: 22px;
  }

  .btn {
    button {
      width: 99.5%;
      margin: 1px;
      border-radius: 15px;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      gap: 12px;
      justify-content: center;
      height: 100%;
      background: #2e2e2e;

      &:hover {
        background: #2e2e2e;
      }

      span {
        color: var(--white);

        @include tTitle_3-semibold;

        @include respond(laptop) {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.12px;
          margin-left: 8px;
        }

        @include respond(tabletL) {
          display: none;
        }
      }

      .icon {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include respond(laptop) {
          width: 24px;
          height: 24px;
        }
      }
    }

    &__bg {
      border-radius: 16px;
      background: linear-gradient(235.35deg, #353535 3.89%, #353535 94.43%);
      box-shadow: 0 4px 38px rgb(0 0 0 / 9%);

      &_black {
        button {
          width: 99.5%;
          margin: 1px;
          border-radius: 15px;
          border: none;
          outline: none;
          display: flex;
          align-items: center;
          gap: 12px;
          justify-content: center;
          height: 100%;
          background: #2e2e2e;

          &:hover {
            background: #2e2e2e;
          }

          span {
            color: var(--white);

            @include tTitle_3-semibold;

            @include respond(tablet) {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
