@use "src/_app/styles/mixins.scss" as *;
.features__body {
  padding: 48px;
  background: #141414;
  border-radius: 24px;

  @include respond(laptop) {
    gap: 34px 36px;
    padding: 32px;
  }

  @include respond(tablet) {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    background: none;
    padding: 0;

    @include respond(tablet) {
      row-gap: 24px;
    }
  }

  &__btn {
    background: #1d1d1d;
    border-radius: 10px;
    border: none;
    outline: none;
    color: var(--white);

    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;

    &.features_desktop {
      @include respond(tablet) {
        display: none;
      }
    }

    &.features_mobile {
      display: none;

      @include respond(tablet) {
        display: flex;
      }
    }

    @include respond(laptopL) {
      height: 72px;
    }

    @include respond(tablet) {
      display: flex;
      flex-direction: column;
      background: none;
      padding: 0;

      row-gap: 0;
    }

    &__btn {
      background: #1d1d1d;
      border-radius: 10px;
      border: none;
      outline: none;
      color: var(--white);

      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;

      @include respond(laptopL) {
        height: 72px;
      }

      @include respond(tablet) {
        width: 128px;
        height: 48px;
        align-self: center;
        text-transform: none;
        margin-top: 24px;
      }
    }
  }
}

.features__items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, max-content);
  gap: 48px 61px;

  @include respond(laptop) {
    gap: 34px 36px;
  }

  @include respond(tablet) {
    display: flex;
    flex-direction: column;
    row-gap: 0;

    background: #141414;
    border-radius: 16px;

    padding: 0 16px;
  }
}
