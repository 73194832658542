@use "src/_app/styles/mixins.scss" as *;
.block__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 392px;
  margin: 51px auto 60px;

  @include respond(laptop) {
    margin: 56px auto 55px;
  }

  @include respond(tablet) {
    max-width: 100%;
    margin: 12px auto 24px;
  }

  .input__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 16px;

    @include respond(mobileL) {
      margin-bottom: 8px;
    }

    label {
      color: #666;

      @include tCaption;
      margin: 0 0 4px;

      @include respond(tablet) {
        display: none;
      }
    }

    input {
      outline: none;
      background: #131313;
      border: 2px solid #333;
      box-shadow: 0 4px 27px rgb(255 255 255 / 14%);
      border-radius: 16px;
      height: 62px;
      padding-left: 19px;

      @include tSubtitle;
      color: var(--white);

      &::placeholder {
        @include tSubtitle;
        color: var(--white);
      }

      @include respond(mobileL) {
        height: 44px;
      }
    }
  }

  .btn_bg {
    width: 100%;
    height: 62px;
    border-radius: 16px;
    background: linear-gradient(235.35deg, #353535 3.89%, #353535 94.43%);

    @include respond(mobileL) {
      height: 44px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      width: 99.5%;
      height: 100%;
      margin: 1px;
      background: #2e2e2e;
      border-radius: 16px;
      color: var(--white);

      @include tTitle_3-semibold;

      @include respond(tablet) {
        font-size: 14px;
        line-height: 20px;
      }

      &:hover {
        background: #2e2e2e;
      }
    }
  }
}
