@use "src/_app/styles/mixins.scss" as *;
.inputContainer {
  .input {
    width: 100%;
    position: relative;
    height: 60px;
    border: 1px solid var(--border_1);
    border-radius: 12px;
    padding: 18px 16px;
    background: var(--bg_6);
    color: var(--primary);

    font-family: Golos, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    &:focus {
      outline: none;
      border: solid 1px #9e9e9e;
    }

    &.error {
      border: 1px solid rgb(197 58 58);

      @include placeholder {
        color: rgb(197 58 58);
      }
    }
  }

  p {
    margin: 0;
    height: 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: rgb(197 58 58);

    @include respond(tablet) {
      height: auto;
      min-height: 20px;
    }
  }
}
