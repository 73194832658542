@use "src/_app/styles/mixins.scss" as *;
.container {
  width: 100%;
  height: 100%;

  border-radius: 24px;
  background-color: #fff;

  background-repeat: no-repeat;
  background-position: left;
  background-image: url('/img/banners/exchange-banner/exchange-banner-desktop-light.png');

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  padding: 130px 75px;

  h2.title {
    color: var(--primary);
    font-size: 48px;
    max-width: 57%;
    text-align: start;

    @include rtl {
      text-align: end;
    }

    @include respond(laptopL) {
      max-width: 55%;
      font-size: 40px;
    }

    @include respond(laptop) {
      max-width: 55%;
      font-size: 28px;
    }

    @include respond(tablet) {
      max-width: 80%;
      font-size: 22px;
      text-align: center;

      @include rtl {
        text-align: center;
      }
    }

    @include respond(mobile) {
      max-width: 100%;
      font-size: 18px;
    }
  }

  @include respond(laptop) {
    padding: 150px 40px;
    background-image: url('/img/banners/exchange-banner/exchange-banner-tablet-light.png');
  }

  @include respond(tablet) {
    background-position: center;
    align-items: center;

    @include rtl {
      align-items: center;
    }
    padding: 72px 27px 32px;
    background-image: url('/img/banners/exchange-banner/exchange-banner-mobile-light.png');
  }

  &.dark {
    background-color: #0a0a0a;
    background-image: url('/img/banners/exchange-banner/exchange-banner-desktop-dark.png');

    @include respond(laptop) {
      background-image: url('/img/banners/exchange-banner/exchange-banner-tablet-dark.png');
    }

    @include respond(tablet) {
      background-image: url('/img/banners/exchange-banner/exchange-banner-mobile-dark.png');
    }
  }
}
