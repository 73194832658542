@use "src/_app/styles/mixins.scss" as *;
.button {
  @include transition;
  @include tButton_2;
  border-radius: 10px;
  box-sizing: border-box;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
}

.adaptivePadding {
  &.xxl {
    @include respond(laptop) {
      padding: 14px 24px;
    }
  }
}

.xxl {
  padding: 18px 24px;
}

.xl {
  padding-top: 19px;
  padding-bottom: 19px;
}

.l {
  padding-top: 17px;
  padding-bottom: 17px;
}

.m {
  padding-top: 16px;
  padding-bottom: 16px;
}

.s {
  padding-top: 13px;
  padding-bottom: 13px;
}

.xs {
  padding-top: 9px;
  padding-bottom: 9px;
}

.full_width {
  width: 100%;
}

.primary {
  background: var(--primary);
  color: var(--secondary);
  border: 1px solid transparent;

  &:hover {
    background: var(--btnPrimaryHover);
  }

  &:disabled {
    background: var(--btnPrimaryHover);
    pointer-events: none;
  }

  &:active {
    background: var(--btnPrimaryActive);
  }

  &.white {
    background: #fff;
    color: #000;

    &:hover {
      background: rgb(255 255 255 / 80%);
    }

    &:disabled {
      background: rgb(255 255 255 / 80%);
    }

    &:active {
      background: rgb(255 255 255 / 90%);
    }
  }
}

.secondary {
  background: transparent;
  color: var(--primary);
  border: 1px solid var(--primary);

  &:hover {
    background: var(--btnSecondaryHover);
  }

  &:disabled {
    background: var(--btnSecondaryHover);
  }

  &:active {
    background: var(--btnSecondaryActive);
  }

  &.white {
    background: transparent;
    color: #fff;
    border: 1px solid #fff;

    &:hover {
      background: rgb(255 255 255 / 14%);
    }

    &:disabled {
      background: rgb(255 255 255 / 14%);
    }

    &:active {
      background: rgb(255 255 255 / 16%);
    }
  }

  &.without_border {
    border: 1px solid transparent;
  }
}

.tertiary {
  background: none;
  color: var(--primary);
  border: none;
  outline: none;

  &:hover {
    background: var(--btnSecondaryActive);
  }

  &:active {
    background: var(--bg_staking);
  }
}

.gray {
  background: var(--bg_staking);
  color: var(--primary);
  border: none;

  &:hover {
    background: var(--btnSecondaryHover);
  }

  &:disabled {
    background: var(--btnSecondaryHover);
  }

  &:active {
    background: var(--btnPrimaryActive);
  }
}

.outline14 {
  background: none;
  outline: none;
  border: none;
  padding: 0;

  color: var(--text_4);

  @include tCaption;
  font-weight: 500;

  &:hover {
    color: var(--primary);
  }
}

.white {
  background: #fff;
  color: #000;
  border: 1px solid #fff;

  &:hover {
    background: rgb(255 255 255 / 80%);
  }

  &:disabled {
    background: rgb(255 255 255 / 80%);
  }

  &:active {
    background: rgb(255 255 255 / 90%);
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;

  @include spinnerAnimation;
}
