@use "src/_app/styles/mixins.scss" as *;
.notifications {
  height: 632px;
}

.header_btn {
  &:focus-visible {
    outline-color: var(--white) !important;
  }
}
