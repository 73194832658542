@use "src/_app/styles/mixins.scss" as *;
.accordion__body__item {
  color: var(--primary);

  &:not(:last-child) {
    border-bottom: 1px solid var(--border_6);
  }

  &__container {
    padding: 32px;

    @include respond(tabletS) {
      padding: 24px;
    }
  }

  &__header {
    margin-bottom: 14px;
  }

  &__position {
    @include tCaption;
    font-weight: 600;
    margin: 0;
  }

  &__name {
    @include tSubtitle-3-medium;
    margin: 0;
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 14px;

    @include respond(tabletS) {
      grid-template-columns: 1fr;
      row-gap: 16px;
    }
  }
}
