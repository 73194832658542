@use "src/_app/styles/mixins.scss" as *;
.hide_in_mobile {
  display: block;

  @include respond(tabletS) {
    display: none;
  }
}

.hide_in_tablet {
  display: block;

  @include respond(tablet) {
    display: none;
  }
}

.control_btn {
  &:focus-visible {
    outline-color: var(--white) !important;
  }
}
