@use "src/_app/styles/mixins.scss" as *;
.container {
  display: flex;
  flex-direction: column;
  background: var(--dropdown_bg);
  height: 100%;
  width: 100%;
  position: relative;
}

.notifications_list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border-top: 1px solid var(--border_header);
  height: calc(100% - 56px);

  &__bottom {
    transform: translateY(-100px);
  }

  .list__loading {
    color: var(--primary);
    padding: 20px 0 12px;
    display: flex;
    justify-content: center;
  }

  &.some_selected {
    padding-bottom: 68px;
  }
}
