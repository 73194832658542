@use "src/_app/styles/mixins.scss" as *;
.contacts__accordion__row {
  overflow: hidden;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--primary);
    cursor: pointer;

    h2 {
      @include tTitle_2_semibold;

      @include respond(tabletS) {
        font-size: 18px;
        line-height: 24px;
      }
      padding: 24px 0;
      margin: 0;
    }
  }

  &__btn {
    transition: transform 0.4s;

    &_minus {
      path {
        stroke: var(--primary);
      }
    }

    path {
      fill: var(--primary);
    }

    &_active {
      transform: rotate(180deg);
    }
  }

  &__body {
    border-radius: 24px;
    background-color: var(--bg_24);
  }

  border-bottom: 1px solid var(--border_6);

  &_active {
    border-bottom: none;
  }
}
