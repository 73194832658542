@use "src/_app/styles/mixins.scss" as *;
.featuresBlock {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;

  @include respond(tabletS) {
    grid-template-columns: 1fr;
    gap: 24px;
  }
}

.blockHeader {
  margin: 0 0 16px;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;

  @include respond(tabletS) {
    font-size: 18px;
    line-height: 24px;
  }
}

.listWrapper {
  @include respond(tabletS) {
    &:nth-child(2) {
      order: 3;
    }

    &:nth-child(3) {
      order: 2;
    }

    &:nth-child(4) {
      order: 4;
    }
  }
}

.blockList {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 12px;

    @include respond(tabletS) {
      gap: 8px;
    }
  }
}

.featureIcon {
  width: 24px;
  height: 24px;
}

.featureText {
  margin: 0;

  @include tSubtitle_Semibold;

  @include respond(mobileL) {
    font-weight: 400;
  }
}
