@use "src/_app/styles/mixins.scss" as *;
.features__wrapper {
  display: flex;
  flex-direction: column;

  max-width: 1280px;
  position: relative;
  margin: 0 auto 100px;

  @include respond(laptop) {
    max-width: 960px;
  }

  @include respond(tablet) {
    margin-bottom: 40px;
  }
}
