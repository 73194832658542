@use "src/_app/styles/mixins.scss" as *;
.invoice__btn {
  cursor: pointer;
  font-family: Golos, sans-serif;
  padding: 18px;
  width: 100%;
  border: none;
  background: var(--primary);
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  &_disabled {
    background: var(--bg_btn_disabled);
  }

  p {
    color: var(--secondary);
    margin: 0;
    height: 20px;

    @include tButton_2;

    display: flex;
    align-items: center;
    column-gap: 10px;

    svg {
      flex-shrink: 0;
      transform: scale(0.9);

      path {
        fill: var(--secondary);
      }
    }
  }

  &_dark {
    background-color: rgb(255 255 255 / 36%) !important;

    p {
      color: var(--black);
    }
  }
}
