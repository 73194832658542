@use "src/_app/styles/mixins.scss" as *;
.crms {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 24px;
  background: var(--bg_mercuryo_banner);
  color: var(--primary);
  padding: 0 80px;
  text-align: start;
  justify-content: flex-start;

  @include respond(laptop) {
    padding: 0 40px;
  }

  @include respond(tablet) {
    padding: 34px 14px 0;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
  }

  @include respond(tabletS) {
    &.long_text {
      padding: 34px 10px 0;
    }
  }

  &__content {
    margin: auto 0;
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    max-width: 42%;

    @include respond(laptop) {
      max-width: 36%;
    }

    @include respond(tabletL) {
      max-width: 45%;
    }

    @include respond(tablet) {
      max-width: calc(100% - 16px);
      margin: 0;
      row-gap: 8px;
    }

    .content__title {
      margin: 0;

      @include tTitle_1_main;

      @include respond(laptop) {
        font-size: 44px;
        line-height: 56px;
      }

      @include respond(tablet) {
        font-size: 28px;
        line-height: 34px;
      }
    }

    .content__description {
      margin: 0;

      @include tTitle_2;
      font-size: 22px;

      @include respond(laptop) {
        max-width: 90%;
      }

      @include respond(tablet) {
        @include tSubtitle;
        letter-spacing: unset;
        max-width: 100%;
      }
    }
  }

  &__image {
    position: absolute;
    left: 46%;

    @include respond(laptop) {
      left: 38%;
    }

    @include respond(tabletL) {
      left: 45%;
    }

    @include respond(tablet) {
      left: 50%;
      transform: translateX(-50%);
      bottom: -1%;
    }

    @include respond(tabletS) {
      &.long_text {
        bottom: -11%;
      }
    }
  }
}
