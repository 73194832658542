@use "src/_app/styles/mixins.scss" as *;
.modal__shadow {
  position: fixed;
  inset: 0;
  top: 0;
  height: 100%;
  z-index: 1001;
  background: var(--boxShadow);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;

  @include respond(tablet) {
    align-items: center;
  }
}

.modal_actions__title {
  @include tTitle_2_semibold;
  overflow-wrap: break-word;
  color: var(--primary);
  text-align: left;
  padding: 0 16px;
}

.modal__wrapper {
  margin: 32px;
  max-width: 100%;
  max-height: 95vh;

  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  z-index: 1;

  text-align: center;

  box-shadow:
    0 8px 16px rgb(37 33 41 / 4%),
    0 0 6px rgb(37 33 41 / 4%);

  min-width: 400px;

  background: var(--bg_18);

  @include respond(tablet) {
    padding-top: 20px;
    min-width: 0;
    max-width: 100%;
  }

  @include respond(tabletS) {
    height: 100%;
    width: 100%;
    max-height: none;
    border-radius: 0;
    margin: 0;
  }
}

.modal__content {
  padding: 48px;

  height: inherit;
  scrollbar-width: none;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 95vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    //  display: none;
  }

  & > *:last-child {
    margin-bottom: 16px;
    background: var(--bg_18);

    border-radius: 0 0 24px 24px;
  }

  @include respond(tablet) {
    padding: 16px;
  }
}

.border {
  border-top: 1px solid var(--border_9);
}

.noPadding {
  padding: 0;
}

.modal__close {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--primary);

  @include respond(mobileL) {
    position: fixed;
    right: 23px;
  }
}
