@use "src/_app/styles/mixins.scss" as *;
.shadow_wrapper {
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  top: 0;
  inset-inline-start: 0;
  z-index: 99;
  background: var(--aurora_modal_bg);

  @include respond(tabletS) {
    display: none;
  }
}

.burger {
  position: fixed;
  height: 100%;
  top: 0;
  inset-inline-start: 0;
  z-index: 1000;

  @include customScrollbar;

  &__container {
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--aurora_select_default);
    z-index: 100;

    @include transition;

    .burger__wrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 200px;
    height: 100dvh;
    background: var(--aurora_select_default);
    inset-inline-end: 99%;
    top: 0;
    z-index: 99;
  }

  &.right {
    inset-inline-start: unset;
    inset-inline-end: 0;

    &::before {
      inset-inline-end: unset;
      inset-inline-start: 99%;
    }
  }
}
