@use "src/_app/styles/mixins.scss" as *;
.redesign {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  border-radius: 24px;
  background: var(--bg_mercuryo_banner);
  color: var(--primary);
  padding-left: 75px;
  text-align: start;

  background-image:
    url('/img/banners/redesign-banner/redesign-img.png'),
    url('/img/banners/redesign-banner/redesign-shadow.png');
  background-repeat: no-repeat;
  background-position:
    100% 50%,
    50% 0;
  background-size:
    951px 550px,
    100%;

  @include respond(laptopC) {
    background-image:
      url('/img/banners/redesign-banner/redesign-img-tablet.png'),
      url('/img/banners/redesign-banner/redesign-shadow.png');
    background-size:
      737px 550px,
      100%;

    padding-left: 40px;
    row-gap: 58px;
  }

  @include respond(laptop) {
    background-position:
      calc(100% + 20px) 50%,
      50% 0;
  }

  @include respond(tabletL) {
    background-image: url('/img/banners/redesign-banner/redesign-img-mobile.png');
    background-size: 343px 447px;
    background-position: 50% 100px;
    padding: 32px 16px;
    justify-content: space-between;
    align-items: center;
  }

  @include respond(tablet) {
    background-position: 50% 80px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 70px;
    min-height: 356px;
    max-width: 37%;

    @include respond(tabletL) {
      align-items: center;
      max-width: 100%;
      min-height: 100%;
    }
  }

  &.long_lang {
    @include respond(tablet) {
      padding-bottom: 16px;
      background-position: 50% 110px;
    }

    .content {
      max-width: 42%;

      @include respond(tabletL) {
        max-width: 100%;

        .title {
          font-size: 40px;
          line-height: 34px;
        }
      }

      @include respond(tablet) {
        background-position: 50% 100px;

        .title {
          font-size: 24px;
          line-height: 28px;
        }

        .description {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    row-gap: 14px;

    @include respond(tabletL) {
      padding: 0;
      row-gap: 8px;
      align-items: center;
      text-align: center;
      max-width: 600px;
    }

    .title {
      @include tTitle_1_main;
      line-height: 60px;
      margin: 0;

      @include respond(laptop) {
        font-size: 40px;
        line-height: 56px;
      }

      @include respond(tablet) {
        font-size: 28px;
        line-height: 34px;
      }
    }

    .description {
      font-size: 22px;
      line-height: 32px;
      margin: 0;

      @include respond(tablet) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .image {
    @include respond(mobile) {
      transform: scale(0.8);
      width: 100%;
    }
  }

  .btn {
    button {
      padding: 16px 24px;
    }
  }
}
