@use "src/_app/styles/mixins.scss" as *;
@mixin before {
  content: '';
  box-sizing: border-box;
  border: 2px solid var(--primary);
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;

  @include transition;

  @include respond(tabletS) {
    border: 2px solid var(--border_4);
  }
}

.custom_checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
  right: 0;
}

.custom_checkbox + label {
  display: grid;
  align-items: center;
  grid-template-columns: 20px auto;
  user-select: none;
  gap: 10px;
  color: var(--primary);
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.12px;

  @include respond(mobileL) {
    font-size: 14px;
    line-height: 20px;
    gap: 18px;
  }

  p {
    margin: 0;
  }

  a {
    color: var(--primary);
    font-weight: 600;
    text-decoration: underline;
  }

  &::before {
    width: 20px;
    height: 20px;

    @include before;
  }

  &.small {
    &::before {
      width: 16px;
      height: 16px;

      @include before;
    }
  }

  &.error {
    color: rgb(197 58 58);
  }
}

.custom_checkbox_wrapper_oneRow + label {
  grid-template-columns: 20px max-content;

  @include respond(tabletS) {
    grid-template-columns: 20px auto;
  }
}

// чекбокс выбран
.custom_checkbox:checked + label::before {
  border-color: transparent;
  background-color: var(--primary);
  background-image: url("data:image/svg+xml,%3csvg width='8' heigh='7' viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 1L2.63636 5.36364L1 3.72727' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center center;
}

.custom_checkbox.dark:checked + label::before {
  background-image: url("data:image/svg+xml,%3csvg width='8' heigh='7' viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 1L2.63636 5.36364L1 3.72727' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
}

/* стили при наведении курсора на checkbox */
.custom_checkbox:not(:disabled, :checked) + label:hover::before {
  // border-color: #b3d7ff;
}

/* стили для активного состояния чекбокса (при нажатии на него) */
.custom_checkbox:not(:disabled):active + label::before {
  // background-color: #b3d7ff;
  // border-color: #b3d7ff;
}

/* стили для чекбокса, находящегося в фокусе */
.custom_checkbox:focus + label::before {
  // box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
.custom_checkbox:focus:not(:checked) + label::before {
  // border-color: #80bdff;
}

/* стили для чекбокса, находящегося в состоянии disabled */
.custom_checkbox:disabled + label::before {
  background-color: rgb(0 0 0 / 36%);
}
