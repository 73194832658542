@use "src/_app/styles/mixins.scss" as *;
.white_label {
  &__title {
    color: var(--white);

    @include tTitle_1_banner;
    max-width: 1000px;
    margin: 100px auto;
    text-align: center;

    @include respond(tablet) {
      font-size: 36px;
      line-height: 100%;
      margin: 40px auto;
      text-align: left;
    }
  }

  &__container {
    background-image: url('/img/services/business/header-bg.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 100px;

    @include respond(tablet) {
      margin-bottom: 40px;
      background-image: none;
    }

    .banner {
      &__container {
        display: grid;
        grid-template-columns: 1fr minmax(auto, 578px);
        align-items: center;
        column-gap: 47px;

        padding: 32px 44px 0;
        background-color: #0f0f0f;
        border-radius: 24px;

        background-image: radial-gradient(#242424 2px, transparent 0);
        background-size: 56px 56px;
        background-position: 18px -6px;
        overflow: hidden;

        @include respond(laptopL) {
          grid-template-columns: 1fr minmax(auto, 578px);
          column-gap: 46px;
          padding: 32px 32px 0;
        }

        @include respond(tabletL) {
          grid-template-columns: 1fr minmax(auto, 454px);
        }

        @include respond(tablet) {
          padding: 24px 16px;
          grid-template-columns: 1fr;
        }

        @include respond(tablet) {
          background-image: none;
          padding: 24px 16px 0;
        }
      }

      &__texts {
        &__title {
          color: var(--white);
          margin: 0 0 32px;

          @include tTitle_1_main;

          @include respond(laptop) {
            font-size: 32px;
            line-height: 42px;
          }

          @include respond(tabletL) {
            font-size: 24px;
            line-height: 32px;
          }
        }

        &__footer {
          display: flex;
          column-gap: 16px;

          .contactUs__btn {
            border: 1px solid var(--white);
            color: var(--white);
          }

          a.signUp__btn {
            height: auto;
          }

          .signUp__btn button {
            color: var(--black);
          }

          @include respond(tablet) {
            display: none;
          }

          &_mobile {
            display: none;

            button {
              width: 100%;
            }

            @include respond(tablet) {
              display: flex;
              flex-direction: column;
              row-gap: 16px;
              margin-top: 24px;
            }
          }
        }
      }
    }
  }
}
