@use "src/_app/styles/mixins.scss" as *;
.contact__link {
  p {
    margin: 0;

    @include tCaption;
    color: var(--text_1);
  }

  a,
  span {
    margin: 0;

    @include tSubtitle;
    color: var(--primary);
  }
}
