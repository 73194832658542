@use "src/_app/styles/mixins.scss" as *;
.crms_banner {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 60px 80px;
  background-color: var(--secondary);
  color: var(--primary);
  height: 100%;
  background-image:
    var(--cmrs_minted_background_url), var(--cmrs_minted_gradient);
  background-repeat: no-repeat;
  background-position: var(--cmrs_banner_image_margin) 100%;
  background-size: auto 100%;
  border-radius: 24px;

  @include respond(laptop) {
    padding: 40px;
    background-image:
      var(--cmrs_minted_background_tablet), var(--cmrs_minted_gradient);
    background-position: var(--cmrs_banner_image_margin_tablet) 100%;
  }

  @include respond(tablet) {
    padding: 34px 24px 50px;
    background-image:
      var(--cmrs_minted_background_mobile), var(--cmrs_minted_gradient_mobile);

    background-position: 50% 100%;
    background-size: var(--cmrs_banner_image_size_mobile);
    justify-content: center;

    &.long_lang {
      @include respond(tabletS) {
        background-position: 50% 190%;
      }

      @include respond(mobileL) {
        background-position: 50% 250%;
      }
    }
  }

  @include respond(tabletS) {
    padding: 22px 16px;
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    row-gap: 50px;
    max-width: 504px;

    @include respond(laptop) {
      row-gap: 31px;
      max-width: 330px;
    }

    @include respond(tablet) {
      justify-content: space-between;
      max-width: 100%;
      height: 100%;

      @include rtl {
        align-items: center;
      }
    }

    .content__text {
      display: flex;
      flex-direction: column;
      row-gap: 14px;
      text-align: start;

      @include rtl {
        text-align: end;

        @include respond(tablet) {
          text-align: center;
        }
      }

      @include respond(laptop) {
        row-gap: 12px;
      }

      @include respond(tablet) {
        text-align: center;
      }

      h2 {
        margin: 0;

        @include tTitle_1_main;

        @include respond(laptop) {
          font-size: 44px;
          line-height: 56px;
        }

        @include respond(tablet) {
          font-size: 28px;
          line-height: 34px;
        }
      }

      p {
        margin: 0;

        @include tTitle_2;

        @include respond(laptop) {
          font-size: 22px;
        }

        @include respond(tablet) {
          @include tSubtitle;
        }
      }
    }

    .content__services {
      display: flex;
      flex-flow: row nowrap;
      column-gap: 20px;
      align-items: flex-end;

      @include respond(tablet) {
        align-items: center;
        justify-content: center;
        color: var(--white);
        transform: scale(0.6) translateY(16px);
      }

      a {
        width: auto;
        height: 50px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }

      .divider {
        width: 1px;
        height: 40px;
        background: var(--divider_crms);

        @include respond(tablet) {
          background: rgb(255 255 255 / 20%);
          height: 24px;
        }
      }
    }
  }
}
