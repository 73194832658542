@use "src/_app/styles/mixins.scss" as *;
.contacts__accordion {
  &__container {
    background-color: var(--bg_18);
  }

  &__wrapper {
    @include contentWidth1;

    @include respond(tabletS) {
      padding: 40px 16px;
      margin: 0;
    }
  }
}
