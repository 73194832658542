@use "src/_app/styles/mixins.scss" as *;
.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 63px 80px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  background: var(--bg_mercuryo_banner);
  color: var(--primary);
  border-radius: 24px;
  overflow: hidden;

  @include respond(laptop) {
    padding: 93px 40px;
  }

  @include respond(tablet) {
    flex-direction: column;
    padding: 0;
    justify-content: space-between;
    row-gap: 24px;
  }

  .image__wrapper {
    position: absolute;
    right: 0;
    top: 0;
    width: 83%;
    height: 100%;

    img {
      object-fit: cover;
    }

    @include respond(tablet) {
      width: 100%;
    }
  }

  .description {
    z-index: 1;

    @include respond(tablet) {
      padding: 47px 22px 0;
    }

    .title {
      @include tTitle_1_banner;
      line-height: 60px;
      text-align: left;
      margin: 0 0 28px;

      @include respond(laptop) {
        font-size: 64px;
        line-height: 56px;
        margin-bottom: 26px;
      }

      @include respond(tablet) {
        font-size: 36px;
        line-height: 40px;
        text-align: center;
        margin-bottom: 17px;
      }
    }

    .text {
      @include tTitle_2_demibold;
      max-width: 327px;
      text-align: left;

      @include respond(laptop) {
        max-width: 249px;
        font-size: 22px;
        line-height: 32px;
      }

      @include respond(tablet) {
        max-width: 266px;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        font-weight: 400;
      }
    }
  }

  .block {
    width: 766px;
    height: 424px;
    position: relative;

    @include respond(laptop) {
      width: 534px;
      height: 240px;
    }

    @include respond(tabletS) {
      width: 100%;
      height: 194px;
    }
  }
}
