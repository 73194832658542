@use "src/_app/styles/mixins.scss" as *;
.wrapper {
  width: 100%;

  @include respond(tablet) {
    max-height: 110vh;
    height: 100%;
    overflow: hidden auto;
    padding: 0 8px;
  }

  @include respond(tabletS) {
    padding: 0;
  }

  &.burger {
    padding: 0;
  }
}
