@use "src/_app/styles/mixins.scss" as *;
.swiper__wrapper {
  position: relative;
  margin-top: 68px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  height: auto;
  overflow: hidden;

  @include respond(laptopL) {
    max-width: 100%;
  }

  @include respond(tablet) {
    margin-top: 0;
    padding: 0 16px;
  }

  .swiper_list {
    display: flex;
    justify-content: space-between;

    @include contentWidth;
    border-radius: 24px;
    margin-bottom: 24px;
    height: 550px;

    @include respond(laptop) {
      margin-bottom: 16px;
    }

    @include respond(tablet) {
      height: 530px;
    }

    &.CPT {
      @include respond(tablet) {
        max-height: 531px;
        margin-bottom: 16px;
      }
    }

    .swiper_item {
      overflow: hidden;
      padding: 0;
    }
  }

  .swiper_button {
    position: absolute;
    top: 50%;
    background: var(--bg_5);
    width: 52px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 200px;
    border: none;
    box-shadow: 0 4px 36px rgb(0 0 0 / 29%);
    z-index: 2;
    cursor: pointer;
    color: var(--primary);

    svg path {
      fill: var(--primary);
    }

    // FIX IPAD
    @include respond(laptopC) {
      width: 52px;
      height: 52px;
    }

    @include respond(tablet) {
      display: none;
    }

    @include respond(laptop) {
      top: calc(50% - 74px);
    }

    &.back_button {
      left: 8px;
      transform: translateY(-50%) rotate(180deg);

      svg {
        position: relative;
        right: -1px;
      }
    }

    &.forward_button {
      right: 8px;
      transform: translateY(-50%);

      svg {
        position: relative;
        right: -1px;
      }
    }
  }

  .banner__btns {
    width: 100%;
    flex-direction: column;
    display: none;
    gap: 16px;

    @include transition;
    min-height: 131px;

    &.hidden {
      display: none;
    }

    a {
      height: 58px;
    }

    @include respond(laptop) {
      display: flex;
    }

    button:first-child {
      margin-bottom: 16px;
    }
  }

  .progress_bar {
    display: flex;
    flex-flow: row nowrap;
    gap: 4px;
    box-sizing: content-box;
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    .progress_point {
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background-color: #666;

      @include transition;

      &.active {
        width: 20px;
        background: var(--mainGray);
      }
    }
  }
}
