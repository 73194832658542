@use "src/_app/styles/mixins.scss" as *;
.mainList {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__item {
    position: relative;

    color: var(--primary_header);

    & > a,
    & > button {
      cursor: pointer;
    }

    &:hover {
      > a,
      > button {
        background: var(--aurora_select_hover);

        div:has(> svg) {
          background: var(--aurora_select_item_hover);
        }
      }
    }

    .arrow {
      position: absolute;
      inset-inline-end: 16px;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      cursor: pointer;

      @include rtl {
        transform: translateY(-50%) rotate(0);
      }

      &.big_padding {
        inset-inline-end: 32px;

        @include respond(tablet) {
          inset-inline-end: 24px;
        }

        @include respond(tabletS) {
          inset-inline-end: 16px;
        }
      }
    }
  }
}

.subItem {
  display: flex;
  flex-direction: column;
  color: var(--primary_header);

  &__back_btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 12px 16px;
    cursor: pointer;
    color: var(--primary_header);

    &:focus-visible {
      outline-offset: -1px;
    }

    svg {
      position: absolute;
      inset-inline-start: 16px;
      top: 50%;
      transform: translateY(-50%);

      @include rtl {
        transform: translateY(-50%) rotate(180deg);
      }
    }

    &.burger {
      height: 80px;
      padding: 28px 32px;

      @include respond(tablet) {
        padding: 28px 24px;
      }

      @include respond(tabletS) {
        height: 60px;
        padding: 18px 16px;
      }

      svg {
        inset-inline-start: 32px;

        @include respond(tablet) {
          inset-inline-start: 24px;
        }

        @include respond(tabletS) {
          inset-inline-start: 16px;
        }
      }
    }
  }
}
