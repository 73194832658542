@use "src/_app/styles/mixins.scss" as *;
.crms__banner {
  position: relative;
  width: 100%;
  height: 100%;

  background-color: var(--secondary);
  background-image: url('/img/banners/crms-forums-banner/crms-forums-banner.png');
  background-size: auto;
  background-position: right;
  background-repeat: no-repeat;

  border-radius: 24px;

  color: var(--primary);

  @include respond(laptopL) {
    background-position-x: 400px;
  }

  @include respond(laptop) {
    background-position-x: 200px;
  }

  @include respond(tablet) {
    background-position: center 130px;
    background-size: 315px;

    background-image: url('/img/banners/crms-forums-banner/crms-forums-banner-mobile.png');
  }

  @include respond(mobileL) {
    background-position: center 140px;
    background-size: 315px;

    background-image: url('/img/banners/crms-forums-banner/crms-forums-banner-mobile.png');
  }

  .content {
    display: flex;
    flex-direction: column;

    gap: 119px;

    align-items: flex-start;

    width: 100%;
    height: 100%;

    max-inline-size: 500px;

    padding-top: 153px;
    padding-left: 44px;

    @include respond(laptop) {
      padding-top: 104px;
      padding-left: 40px;

      gap: 86px;
    }

    @include respond(tablet) {
      padding-top: 30px;
      padding-bottom: 44px;
      padding-inline: 0;

      margin-inline: auto;

      gap: 300px;
    }

    @include respond(tabletS) {
      padding-top: 30px;
      padding-bottom: 44px;
      padding-inline: 0;

      margin-inline: auto;

      gap: 300px;
    }

    @media (width < 460px) {
      padding-top: 30px;
      padding-bottom: 44px;
      padding-inline: 0;

      margin-inline: auto;

      gap: 255px;
    }

    .information {
      display: flex;
      flex-direction: column;

      gap: 14px;

      text-align: start;

      @include respond(laptop) {
        gap: 12px;

        max-inline-size: 286px;
      }

      @include respond(tablet) {
        gap: 8px;

        max-inline-size: 600px;

        padding: 16px;

        margin-inline: auto;

        text-align: center;
      }

      @include respond(mobileL) {
        gap: 8px;

        max-inline-size: 300px;

        margin-inline: auto;

        text-align: center;
      }

      .title {
        @include tTitle_1_main;
        margin: 0;

        @include respond(laptop) {
          font-size: 44px;
          line-height: 56px;
        }

        @include respond(tablet) {
          font-size: 28px;
          line-height: 34px;
        }
      }

      .description {
        @include tTitle_2;
        margin: 0;

        @include respond(laptop) {
          font-size: 22px;
        }

        @include respond(tablet) {
          @include tSubtitle;
        }
      }
    }

    .btn {
      @include respond(tablet) {
        margin-inline: auto;
      }

      button {
        display: flex;
        align-items: center;
        column-gap: 10px;

        @include respond(tablet) {
          padding: 11px 20px;
        }
      }
    }
  }
}
