@use "src/_app/styles/mixins.scss" as *;
.skeletons {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 16px;
  padding: 20px 16px;
  width: 100%;

  // .skeletons__icon {
  //   width: 44px;
  //   height: 44px;
  // }

  &__rows {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;

    .rows__date {
      height: 12px;
      width: 110px;
      margin-bottom: 6px;
    }

    .rows__item {
      height: 16px;

      &:nth-child(odd) {
        min-width: 100%;
        margin-bottom: 12px;
      }

      &:nth-child(even) {
        width: 80%;
        margin-bottom: 6px;
      }
    }
  }
}
