@use "src/_app/styles/mixins.scss" as *;
.cases {
  width: 100%;
  max-width: 1280px;
  margin: auto;
  color: var(--primary);
}

.header {
  margin: 0 auto 68px;
  max-width: 864px;
  text-align: center;
  font-size: 60px;
  font-weight: 700;
  line-height: 72px;

  @include respond(laptop) {
    max-width: 704px;
    line-height: 60px;
    font-size: 48px;
    margin-bottom: 48px;
  }

  @include respond(tablet) {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 24px;
    text-align: left;
  }
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  @include respond(tablet) {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  &__item {
    overflow: hidden;
    padding: 24px 25px 0;
    border-radius: 24px;
    background-color: var(--bg_3);

    @include respond(tablet) {
      padding: 16px 16px 0;
    }
  }

  &__wrapper {
    height: 100%;
    box-shadow: 0 0 80px 0 rgb(0 0 0 / 10%);
    border-radius: 24px 24px 0 0;
    padding: 32px;
    background: var(--bg_47);

    @include respond(tablet) {
      padding: 16px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    @include respond(tablet) {
      margin-bottom: 24px;
    }

    .logo {
      width: 40px;
      height: 40px;
    }

    .title {
      margin: 0 0 0 10px;

      @include tTitle_2_Semibold;

      @include respond(tablet) {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
      }
    }

    .globe {
      @include respond(laptop) {
        display: none;
      }
      background-color: var(--bg_6);
      margin-left: auto;
      padding: 8px;
    }
  }

  &__currency {
    display: flex;
    margin-bottom: 10px;
  }

  &__currency_text {
    margin: 0 8px 0 0;

    @include tTitle_1_demibold;

    @include respond(tablet) {
      font-size: 26px;
      line-height: 36px;
      letter-spacing: -0.72px;
    }
  }

  .copy {
    width: 44px;
    height: 44px;
    padding: 10px;
    box-shadow: var(--shadow_4);
    background-color: var(--bg_43);

    @include respond(tablet) {
      width: 36px;
      height: 36px;
      padding: 6px;
    }
  }

  &__network {
    @include tSubtitle_Semibold;
    margin: 0 0 10px;
  }

  &__additional {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.08px;
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--text_1);

    svg {
      display: flex;
    }

    p {
      margin: 0;
    }
  }
}

.icon {
  border-radius: 40px;

  svg {
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }
}
