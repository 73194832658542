@use "src/_app/styles/mixins.scss" as *;
@mixin contentWidth {
  width: 100%;
  max-width: 1280px;
}

.contactForm {
  @include contentWidth;
  background: var(--bg_3);
  border-radius: 16px;
  overflow: hidden;
  padding: 48px;

  display: flex;
  justify-content: space-between;
  gap: 20px;

  &.without_padding {
    padding: 0;
    background: transparent;
    display: grid;
    grid-template-columns: 1fr minmax(580px, 1fr);
    margin: 0;
    text-align: start;
    gap: 60px;
    overflow: visible;

    @include respond(laptopC) {
      gap: 32px;
      grid-template-columns: 1fr minmax(432px, 1fr);
    }

    @include respond(tabletL) {
      grid-template-columns: 1fr minmax(350px, 1fr);
      gap: 16px;
    }

    @include respond(tablet) {
      display: flex;
      flex-direction: column;

      // max-width: 75vh;
      // overflow-y: scroll;
      .form {
        max-width: 100%;
      }
    }

    .title,
    .form {
      width: 100%;
    }
  }

  @include respond(laptop) {
    padding: 32px;
    gap: 36px;
  }

  @include respond(tabletS) {
    border-radius: 0;
    flex-direction: column;
    gap: 32px;
    background: var(--bg_7);
  }

  form.form {
    @include respond(laptopC) {
      width: 50%;
      justify-content: center;
    }

    @include respond(tablet) {
      width: 100%;
      justify-content: center;
    }
  }

  .title {
    @include respond(laptopC) {
      width: 50%;
      justify-content: center;
    }

    @include respond(tablet) {
      width: 100%;
      justify-content: center;
    }

    ul {
      margin: 0 0 40px;
      padding: 0;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: 24px;

      @include respond(tabletS) {
        margin-bottom: 0;
        gap: 16px;
      }

      li {
        color: var(--primary);
        display: flex;
        align-items: center;

        svg {
          min-width: 24px;
          margin-right: 14px;

          path {
            stroke: var(--primary);
          }
        }
      }

      p {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
    }

    h2 {
      font-weight: 700;
      font-size: 40px;
      line-height: 50px;
      max-width: 544px;
      color: var(--primary);
      margin: 0 0 32px;

      @include respond(laptop) {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 24px;
      }

      @include respond(tablet) {
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
      }

      @include respond(tabletS) {
        max-width: none;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 32px;
      }

      span.highlighted {
        color: var(--white);
        background: var(--bg_4);
        border-radius: 16px;
        padding: 10px;
        white-space: nowrap;

        @include respond(tabletS) {
          padding: 0 10px;
        }
      }
    }
  }

  .form {
    max-width: 580px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    z-index: 1;

    @include respond(laptop) {
      max-width: 436px;
    }

    @include respond(tabletS) {
      max-width: none;
    }

    .inputs {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .form__button {
      width: 100%;

      button {
        margin-top: 48px;
        width: 100%;
      }
    }
  }
}
