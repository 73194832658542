@use "src/_app/styles/mixins.scss" as *;
.item {
  padding: 16px 20px;
  word-break: break-word;
  background-color: var(--dropdown_bg);
  color: var(--primary);
  display: flex;
  flex-flow: row nowrap;
  column-gap: 16px;
  cursor: pointer;

  @include transition;
  text-align: start;

  &:focus-visible {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      inset: 4px 6px;
      border: 1px solid var(--primary);
      border-radius: 5px;
    }
  }

  &.active {
    background: var(--bgHeaderItemHover);
  }

  &__wrapper {
    .item__date {
      display: inline-block;
      color: var(--text_1);
    }

    .item__title {
      margin-bottom: 8px;
    }

    .item__content {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      &_interactive {
        margin-bottom: 16px;
      }

      a {
        text-decoration: underline;

        &:focus-visible {
          outline: none;
          font-weight: 600;
        }
      }
    }

    .item__button {
      width: fit-content;
      width: 100%;
      padding: 0;

      // display: block;

      button,
      span {
        background: #dfdfdf;
        color: var(--black);

        &:hover {
          background: #d4d4d4;
        }

        &:active {
          background: #c7c7c7;
        }
      }
    }
  }

  .selected {
    background-color: var(--select_item_hover);
  }
}
