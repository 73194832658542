@use "src/_app/styles/mixins.scss" as *;
.crms__banner {
  position: relative;
  width: 100%;
  height: 100%;

  background-color: var(--secondary);
  background-image: url('/img/banners/raising-apr-banner/raising-apr-bunner.png');
  background-size: auto;
  background-position: right;
  background-repeat: no-repeat;

  border-radius: 24px;

  color: var(--primary);

  @media (width <= 1300px) {
    background-position: 20px center;
  }

  @include respond(laptopC) {
    background-position: center center;
  }

  @include respond(tablet) {
    background-position: center 150px;
    background-size: 343px 383px;

    background-image: url('/img/banners/raising-apr-banner/raising-apr-banner-mobile.png');
  }

  .content {
    display: flex;
    flex-direction: column;

    gap: 86px;

    align-items: flex-start;

    width: 100%;
    height: 100%;

    padding-top: 113px;
    padding-left: 75px;

    @include respond(laptopC) {
      padding-top: 80px;
      padding-left: 40px;

      gap: 74px;
    }

    @include respond(tablet) {
      justify-content: space-between;

      padding-top: 30px;
      padding-bottom: 30px;
      padding-inline: 0;

      margin-inline: auto;

      gap: 0;
    }

    @include respond(tabletS) {
      padding-top: 30px;
      padding-bottom: 15px;
    }

    .information {
      display: flex;
      flex-direction: column;

      gap: 14px;

      text-align: start;

      @include respond(laptop) {
        gap: 12px;
      }

      @include respond(tablet) {
        gap: 8px;

        max-width: 330px;

        padding: 0;

        margin-inline: auto;

        text-align: center;
      }

      .title {
        max-width: 572px;

        @include tTitle_1_main;
        margin: 0;

        @include respond(laptopC) {
          max-width: 390px;
        }

        @include respond(laptop) {
          font-size: 40px;
          line-height: 56px;
        }

        @include respond(tablet) {
          max-width: 289px;

          padding-inline: 16px;

          font-size: 28px;
          line-height: 34px;
        }

        &.longLanguage {
          font-size: 42px;
          line-height: 54px;

          @include respond(laptopC) {
            font-size: 32px;
            line-height: 44px;
          }

          @include respond(tablet) {
            font-size: 24px;
            line-height: 36px;
          }
        }

        &.superLongLanguage {
          font-size: 38px;
          line-height: 50px;

          @include respond(laptopC) {
            font-size: 28px;
            line-height: 40px;
          }

          @include respond(tablet) {
            font-size: 18px;
            line-height: 30px;
          }
        }
      }

      .description {
        max-width: 533px;
        margin: 0;

        @include tTitle_2;
        font-size: 22px;
        line-height: 32px;

        @include respond(laptopC) {
          max-width: 360px;
        }

        @include respond(tablet) {
          @include tSubtitle;

          max-width: 311px;
        }

        &.superLongLanguage {
          font-size: 20px;
          line-height: 30px;

          @include respond(laptopC) {
            font-size: 20px;
            line-height: 30px;
          }

          @include respond(tablet) {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }

    .btn {
      @include respond(tablet) {
        margin-inline: auto;
      }

      button {
        display: flex;
        align-items: center;
        column-gap: 10px;

        padding: 16px 24px;

        background-color: var(--primary);
        color: var(--secondary);
      }
    }
  }
}
