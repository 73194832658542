@use "src/_app/styles/mixins.scss" as *;
.features__header {
  margin-bottom: 56px;

  @include respond(tablet) {
    margin-bottom: 32px;
  }

  h2 {
    margin: 100px 0 24px;
    max-width: 812px;

    @include tTitle_1_main;
    color: var(--white);

    @include respond(tablet) {
      margin: 40px 0 16px;
      font-size: 28px;
      line-height: 36px;
    }
  }

  .features__header_features {
    display: flex;

    @include respond(tablet) {
      display: flex;
      flex-wrap: wrap;
    }

    p {
      @include tSubtitle-3-medium;
      color: var(--white);
      margin: 0;

      @include respond(tablet) {
        letter-spacing: -0.12px;
      }

      @include respond(laptop) {
        font-size: 18px;
      }

      @include respond(tablet) {
        font-size: 16px;
        line-height: 24px;
      }

      &:not(:last-child) {
        margin-right: 8px;

        span {
          margin-left: 8px;
        }
      }

      &:nth-child(2) {
        span {
          display: none;
        }
      }
    }
  }
}
