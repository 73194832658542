@use "src/_app/styles/mixins.scss" as *;
.app_banner {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: start;
  border-radius: 24px;
  padding: 0 80px;
  color: var(--primary);
  background-color: var(--bg_mercuryo_banner);
  overflow: hidden;
  background-image: var(--app_banner_background_url);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50px 50%;

  @include respond(laptop) {
    background-image: var(--app_banner_background_url_tablet);
    background-position: 0 50%;
    padding: 0 40px;
  }

  @include respond(tablet) {
    background-image: var(--app_banner_background_url_mobile);
    background-position: bottom center;
    align-items: flex-start;
    justify-content: center;
    padding: 22px 32px;
  }

  &.long_lang {
    @include respond(tablet) {
      padding: 22px 16px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    align-items: flex-start;
    justify-content: center;
    max-width: 340px;
    position: relative;
    z-index: 2;

    @include respond(laptop) {
      max-width: 330px;
      row-gap: 12px;
    }

    @include respond(tablet) {
      max-width: 100%;
      flex-direction: column;
      align-items: center;
      text-align: center;
      row-gap: 8px;
    }

    .content__title {
      @include tTitle_1_main;
      margin: 0;

      @include respond(laptop) {
        font-size: 44px;
        line-height: 56px;
      }

      @include respond(tablet) {
        font-size: 28px;
        line-height: 34px;
      }
    }

    .content__descr {
      @include tTitle_2;
      margin: 0;

      @include respond(laptop) {
        font-size: 22px;
      }

      @include respond(tablet) {
        @include tSubtitle;
      }
    }
  }

  &__image {
    position: absolute;
    inset-inline-start: 438px;
    bottom: 0;
    width: 810px;
    height: 550px;

    @include respond(laptop) {
      width: 600px;
      inset-inline-start: 362px;
    }

    @include respond(tablet) {
      width: 508px;
      height: 346px;
      inset-inline-start: 50%;
      transform: translateX(-50%);
    }

    &.long_lang {
      @include respond(tablet) {
        bottom: -12px;
      }
    }
  }
}
